
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'LocationList'
})
export default class MonitorList extends Vue {
  private info = {
    projectName: ''
  }

  private tableData = {
    loading: false,
    tr: [
      {
        label: '项目名称',
        prop: 'projectName',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '设备总数',
        prop: 'totalAmount',
        minWidth: '100',
        showOverflowTooltip: true
      },
      {
        label: '设备在线',
        prop: 'onlineAmount',
        minWidth: '100',
        showOverflowTooltip: true
      }
    ],
    data: []
  }

  page = 1
  size = 10
  total = 0

  created () {
    this.getData()
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.monitor.sumByProject, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  onDetail (id: string) {
    this.$router.push({
      name: 'monitorMapDetail',
      params: { projectId: id }
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
